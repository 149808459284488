app-articles-search-result,
.app-articles-search-result {

  .promo-banner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 12px;
    z-index: 1000;
    background-color: $highlight-color;
    color: $highlight-color-contrast;
    padding: 0.5rem;
    width: 2.5rem;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      width: 2.5rem;
      border-width: 0 1.25rem 1rem 1.25rem;
      border-style: solid;
      border-color: $highlight-color $highlight-color transparent $highlight-color;
    }
  }


  .quantity-in-cart {
    font-weight: normal !important;
    font-size: 1.2rem;
    float: right;
    margin-right: -10px;

    .fa-shopping-cart {
      font-size: 1.5rem;
    }
  }

  .article-dropdown-row {
    border-top: 1px solid rgba(206, 206, 206, 0.25);
    background-color: darken($color-neutral-contrast, 5);

    .article-dropdown {
      padding: 5px;
      margin-left: 12px;

      .fa-angle-down, .fa-angle-up {
        font-size: 18px;
        vertical-align: bottom;
        position: absolute;
        right: 15px;
      }
    }

    &:hover {
      opacity: 0.75;
    }
  }

  .article-dropdown-list-row {

    .alert-info {
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
    }

    .fa-spinner {
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      font-size: 2rem;
    }

    .item-list {
      margin-bottom: 0 !important;
      width: 100%;

      li {
        .row {
          margin-left: -12px !important;
          margin-right: -12px !important;
        }

        margin-left: 3px;
        margin-right: 3px;

        .price-col {
          .price {
            color: $highlight-color;
            font-weight: bold;
            margin-top: 0;
            float: right;
          }
        }

        &.selected {
          border-left: 2px solid $highlight-color;
          border-right: 2px solid $highlight-color;
        }
      }
    }

    &:hover {
      background-color: $color-white !important;
      cursor: default !important;
    }
  }

  .price {
    margin-top: -15px;
    margin-right: -5px;

    &.small {
      margin-top: 0px;
    }
  }

  .availability-panel {
    position: absolute;
    right: 0;
    bottom: 0;

    .selectedSupplier {
      float: left;
      width: 60px;
      margin-top: -7px;
      height: auto;
      margin-right: 10px;
    }

    .price {
      &.small {
        margin-top: -15px;
      }

      float: left;
    }

    .icon {
      float: left;
      margin-top: -16px;
      margin-left: 15px;
    }
  }

  @media screen and (max-width: 1600px) {
    .supplierLogo {
      display: none !important;
    }
    .showMoreSupplier {
      display: none !important;
    }

  }

  .supplier_logos {
    .supplierLogo {
      margin-right: -15px;
      display: inline-block;
    }

    .selectedSupplier {
      float: right;
    }

    img {
      height: 30px;
      width: auto;
      padding: 5px;
      margin: 0 5px;
      margin-bottom: 10px;
    }

    z-index: 2;
    margin-top: 5px;

    .showMoreSupplier {
      margin-right: 60px;

      .toolTipDiv {
        height: 30px;
        width: 50px;
        padding: 5px;
        border: 1px solid $color-grey-light;
        margin: 0 5px;
        border-radius: 15px;
        display: inline-block;
        text-align: center;
        position: absolute;

        .supplierToolTip {
          .tooltip-inner {
            background-color: #dfe6ed !important;
          }

          .arrow::before {
            border-top-color: #dfe6ed !important;
          }
        }
      }
    }

    &.supplier_logs_small {
      img {
        height: 25px !important;
      }

      .supplierLogo {
        height: 25px;
      }
    }
  }

  .row {
    &.pseudo-show-articles {
      background-color: darken($color-neutral-contrast, 10) !important;
      margin: 0 -1rem;
      padding: 1rem;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }

      span {
        margin: 0 auto;
      }
    }

    &.selected {
      background-color: $background-color;
      border-left: 0.2rem solid $highlight-color;
      border-right: 0.2rem solid $highlight-color;
      cursor: pointer;

    }

    &:hover {
      background-color: darken($background-color, 5);
      //border-left: 0.2rem solid $highlight-color ;
      //border-right: 0.2rem solid $highlight-color ;
      cursor: pointer;

      .image-col {
        border: 1px solid $color-grey-light;
      }
    }
  }
}

body {
  .popover {
    background: $font-color;
    max-width: 355px;

    .arrow {
      right: calc(50% - 10px);

      &::after {
        border-top-color: $font-color;
      }
    }

    .popover-body {
      .supplierPopOver {
        .supplier-container {
          display: inline-block;
          min-width: 75px;

          img {
            height: 30px;
            min-width: 75px;
            padding: 5px;
            border: 1px solid $color-grey-light;
            margin: 0 5px;
            border-radius: 15px;
            margin-bottom: 10px;
            background-color: $color-white;
          }
        }

        padding-top: 5px;
      }
    }
  }
}

.hide {
  display: none;
}

.row {
  &.spacer {
    margin-top: 10px;
  }
}
