input[type="checkbox"],
input[type="radio"]{
  appearance: none;
  -moz-appearance: none;
  border: none !important;
  background: transparent;
  min-height: 1rem;
  min-width: 1rem;
  margin-right:15px;
  margin-top: 2px;
  outline:none;
  position:relative;
  cursor:pointer;
  user-select: none;
  display:block;
  border-radius:24px;
  transition:0.28s;
  float: left;

  &:before, &:after {
    box-sizing:border-box;
    min-height: 18px;
    min-width: 18px;
    background: transparent;
    border: 2px solid $font-color;
    content:"";
    position:absolute;
    transition:0.14s ease-out;
    border-radius:2px;
  }
  &:before {
    transform-origin:50% 100%;
    top:-1px;
    left:0px;
    clear: both;
  }

  &:after {
    top:1px;
    left:1px;
    min-height: 12px;
    min-width: 6px;
    border-color: $highlight-color;
    border-width: 0 2px 2px 0;
    transform-origin:100% 100%;
    transform: rotate(45deg) scale(0);
    border-radius:0;
  }

  &:hover {
    //background:#D6E5E4;
  }

  &:disabled {
    //background:lighten($highlight-color,20);
    cursor: not-allowed;
    &:before {
      border-color: #B0B0B0;
    }
  }

  &:checked {

      input[type="checkbox"]:hover {
        background:#474747;
      }

      input[type="checkbox"]:checked:hover {
        background:#2E3D3C;
      }

      input[type="checkbox"]:checked:disabled:hover {
        background:#474747;
      }

      input[type="checkbox"]:before {
        border-color:#686868;
      }

      input[type="checkbox"]:checked:before {
        border-color:$highlight-color;
      }

      input[type="checkbox"]:disabled:before {
        border-color:#686868;
      }

      input[type="checkbox"]:checked:disabled:before {
        border-color:#686868;
        background:#686868;
      }

      input[type="checkbox"]:after {
        border-color:#333333;
      }

    &:before {
      background: $highlight-color;
      border-color: $highlight-color;
    }
    &:after {
      border-color: $highlight-color-contrast;
    }

    &:disabled:before {
      border-color:#B0B0B0;
      background:#B0B0B0;
    }

    &:disabled:hover {
      background:#DDDDDD;
    }

    &:after {
      transform: rotate(45deg) scale(1);
    }
  }
}
